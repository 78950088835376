/* body {
  display: none;
}

.nmslslsls {
  display: none;
} */



.ant-table-tbody>tr>.ant-table-cell {
  padding: 0 !important;
  margin: 0;
}

.ant-table-tbody>tr>.ant-table-cell>div {
  padding: 8px 8px;

}

/* .ant-table-thead>.ant-table-cell {
  padding: 8px 8px !important;
} */